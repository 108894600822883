body {
  text-align: left;
  background-color: #d3d3d3;
  color: black;
}

@tailwind base;

@layer base {
  h1 {
    @apply text-3xl font-bold text-center p-2;
  }

  h2 {
    @apply text-2xl font-bold text-center p-2;
  }

  h3 {
    @apply text-xl font-bold text-center p-2;
  }
}

@tailwind components;
@tailwind utilities;
/* .scrollbar {
    &::-webkit-scrollbar {
      width: 14px;
      background-color: #F5F5F5;
    };
    scrollbar-width: thin;
  }
 */

.scrollbar {
  scrollbar-width: thin;
}

.scrollbar-webkit-scrollbar {
  width: 14px;
  background-color: #f5f5f5;
}
